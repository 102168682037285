/* CORES DA PALETA */
:root {
  --ion-color-cinza-full: #444D55;
  --ion-color-cinza-full-rgb: 68, 77, 85;
  --ion-color-cinza-full-contrast: #ffffff;
  --ion-color-cinza-full-contrast-rgb: 255, 255, 255;
  --ion-color-cinza-full-shade: #3c444b;
  --ion-color-cinza-full-tint: #575f66;
  --ion-color-amarelo: #ffcc02;
}

.bg-cinza-full {
  background-color: var(--ion-color-cinza-full);
}

.bg-amarelo {
  background-color: var(--ion-color-amarelo);
}


.ion-color-cinza-full {
  --ion-color-base: var(--ion-color-cinza-full);
  --ion-color-base-rgb: var(--ion-color-cinza-full-rgb);
  --ion-color-contrast: var(--ion-color-cinza-full-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cinza-full-contrast-rgb);
  --ion-color-shade: var(--ion-color-cinza-full-shade);
  --ion-color-tint: var(--ion-color-cinza-full-tint);
}


.text-cinza-full {
  color: var(--ion-color-cinza-full)
}


:root {
  --ion-color-cinza-medio: #768692;
  --ion-color-cinza-medio-rgb: 118, 134, 146;
  --ion-color-cinza-medio-contrast: #000000;
  --ion-color-cinza-medio-contrast-rgb: 0, 0, 0;
  --ion-color-cinza-medio-shade: #687680;
  --ion-color-cinza-medio-tint: #84929d;
}

.bg-cinza-medio {
  background-color: var(--ion-color-cinza-medio);
}

.color-cinza-medio {
  color: var(--ion-color-cinza-medio);
}

.text-cinza-medio {
  color: var(--ion-color-cinza-medio);
}


.ion-color-cinza-medio {
  --ion-color-base: var(--ion-color-cinza-medio);
  --ion-color-base-rgb: var(--ion-color-cinza-medio-rgb);
  --ion-color-contrast: var(--ion-color-cinza-medio-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cinza-medio-contrast-rgb);
  --ion-color-shade: var(--ion-color-cinza-medio-shade);
  --ion-color-tint: var(--ion-color-cinza-medio-tint);
}

:root {
  --ion-color-cinza-light: #8C8C8C;
  --ion-color-cinza-light-rgb: 140, 140, 140;
  --ion-color-cinza-light-contrast: #000000;
  --ion-color-cinza-light-contrast-rgb: 0, 0, 0;
  --ion-color-cinza-light-shade: #7b7b7b;
  --ion-color-cinza-light-tint: #989898;
}

.ion-color-cinza-light {
  --ion-color-base: var(--ion-color-cinza-light);
  --ion-color-base-rgb: var(--ion-color-cinza-light-rgb);
  --ion-color-contrast: var(--ion-color-cinza-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cinza-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-cinza-light-shade);
  --ion-color-tint: var(--ion-color-cinza-light-tint);
}

:root {
  --ion-color-verde: #4D2E00;
  --ion-color-verde-rgb: 67, 128, 122;
  --ion-color-verde-contrast: #ffffff;
  --ion-color-verde-contrast-rgb: 255, 255, 255;
  --ion-color-verde-shade: #4D2E00;
  --ion-color-verde-tint: #4D2E00;
}


.ion-color-verde {
  --ion-color-base: var(--ion-color-verde);
  --ion-color-base-rgb: var(--ion-color-verde-rgb);
  --ion-color-contrast: var(--ion-color-verde-contrast);
  --ion-color-contrast-rgb: var(--ion-color-verde-contrast-rgb);
  --ion-color-shade: var(--ion-color-verde-shade);
  --ion-color-tint: var(--ion-color-verde-tint);
}

.bg-verde {
  background-color: var(--ion-color-verde);
}

.bg-black {
  background-color: var(--ion-color-bege-contrast);
}

.color-verde {
  color: var(--ion-color-verde) !important;
}


:root {
  --ion-color-bege: #C5B9AC;
  --ion-color-bege-rgb: 197, 185, 172;
  --ion-color-bege-contrast: #000000;
  --ion-color-bege-contrast-rgb: 0, 0, 0;
  --ion-color-bege-shade: #ada397;
  --ion-color-bege-tint: #cbc0b4;
}

.ion-color-bege {
  --ion-color-base: var(--ion-color-bege);
  --ion-color-base-rgb: var(--ion-color-bege-rgb);
  --ion-color-contrast: var(--ion-color-bege-contrast);
  --ion-color-contrast-rgb: var(--ion-color-bege-contrast-rgb);
  --ion-color-shade: var(--ion-color-bege-shade);
  --ion-color-tint: var(--ion-color-bege-tint);
}

:root {
  --ion-color-vinho: #4A3041;
  --ion-color-vinho-rgb: 74, 48, 65;
  --ion-color-vinho-contrast: #ffffff;
  --ion-color-vinho-contrast-rgb: 255, 255, 255;
  --ion-color-vinho-shade: #412a39;
  --ion-color-vinho-tint: #5c4554;
}

.ion-color-vinho {
  --ion-color-base: var(--ion-color-vinho);
  --ion-color-base-rgb: var(--ion-color-vinho-rgb);
  --ion-color-contrast: var(--ion-color-vinho-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vinho-contrast-rgb);
  --ion-color-shade: var(--ion-color-vinho-shade);
  --ion-color-tint: var(--ion-color-vinho-tint);
}

:root {
  --ion-color-tijolo: #4D4637;
  --ion-color-tijolo-rgb: 185, 89, 70;
  --ion-color-tijolo-contrast: #ffffff;
  --ion-color-tijolo-contrast-rgb: 255, 255, 255;
  --ion-color-tijolo-shade: #4D4637;
  --ion-color-tijolo-tint: #4D4637;
}

.bg-tijolo {
  background-color: var(--ion-color-tijolo);
}

.text-tijolo {
  color: var(--ion-color-tijolo) !important;
}

.color-tijolo {
  color: var(--ion-color-tijolo);
}

.ion-color-tijolo {
  --ion-color-base: var(--ion-color-tijolo);
  --ion-color-base-rgb: var(--ion-color-tijolo-rgb);
  --ion-color-contrast: var(--ion-color-tijolo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tijolo-contrast-rgb);
  --ion-color-shade: var(--ion-color-tijolo-shade);
  --ion-color-tint: var(--ion-color-tijolo-tint);
}

.ion-color-amarelo {
  --ion-color-base: var(--ion-color-amarelo);
  --ion-color-base-rgb: var(--ion-color-amarelo);
  --ion-color-contrast: var(--ion-color-cinza-full);
  --ion-color-contrast-rgb: var(--ion-color-cinza-full);
  --ion-color-shade: var(--ion-color-amarelo);
  --ion-color-tint: var(--ion-color-amarelo);
}

:root {
  --ion-color-cinza-texto: #757575;
  --ion-color-cinza-texto-rgb: 117, 117, 117;
  --ion-color-cinza-texto-contrast: #ffffff;
  --ion-color-cinza-texto-contrast-rgb: 255, 255, 255;
  --ion-color-cinza-texto-shade: #676767;
  --ion-color-cinza-texto-tint: #838383;
}

.ion-color-cinza-texto {
  --ion-color-base: var(--ion-color-cinza-texto);
  --ion-color-base-rgb: var(--ion-color-cinza-texto-rgb);
  --ion-color-contrast: var(--ion-color-cinza-texto-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cinza-texto-contrast-rgb);
  --ion-color-shade: var(--ion-color-cinza-texto-shade);
  --ion-color-tint: var(--ion-color-cinza-texto-tint);
}

.color-cinza-texto {
  color: var(--ion-color-cinza-texto);
}

:root {
  --ion-color-input: #F4F1EE;
  --ion-color-input-rgb: 244, 241, 238;
  --ion-color-input-contrast: #000000;
  --ion-color-input-contrast-rgb: 0, 0, 0;
  --ion-color-input-shade: #d7d4d1;
  --ion-color-input-tint: #f5f2f0;
}

.ion-color-input {
  --ion-color-base: var(--ion-color-input);
  --ion-color-base-rgb: var(--ion-color-input-rgb);
  --ion-color-contrast: var(--ion-color-input-contrast);
  --ion-color-contrast-rgb: var(--ion-color-input-contrast-rgb);
  --ion-color-shade: var(--ion-color-input-shade);
  --ion-color-tint: var(--ion-color-input-tint);
}


/* CORES DA PALETA */