/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/paleta.scss";

:root {
  --ion-font-family: "Arial";

  ion-input {
    --background: var(--ion-color-input);
    --placeholder-color: var(--ion-color-cinza-texto);
    border-radius: 4px;
    --padding-start: 8px;
  }
}

.toast-contact {
  --background: var(--ion-color-tijolo);
  --border-color: var(--ion-color-tijolo);
  --border-radius: 5px;
  --border-style: solid;
  --border-width: 1px;
  --button-color: white;
  --color: #fff;
  --max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  font-size: 22px;
  text-align: center;

  &.login {
    font-size: 17px;
  }
}

.alert-login {
  --background: var(--ion-color-tijolo);
  --max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;



  .alert-message {
    font-size: 17px;
    color: white;
    max-height: 100%;
  }

  .alert-button-group {
    position: absolute;
    right: 0;
    width: 50px;

    button {
      color: white;
    }
  }
}
.alert-enquete {
  --background: var(--ion-color-tijolo);
  --max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  --color: white;
  border-radius: 5px;

.alert-message {
  font-size: 17px;
  color: white;
  height: 180px;
}

.alert-button-group {
  position: absolute;
  bottom: 20px;
  width: 100%;
  margin-top: 41px;

  button {
    background: white;
    color: var(--ion-color-cinza-texto);
    margin: 0 10px 0 auto;
    border-radius: 10px;
    max-width: 40%;
    min-width: 20%;
    font-size: 0.8rem;
    text-transform: uppercase;
    &.alert-button:last-child{
      margin: 0 auto 0 10px;
    }
  }
}
}

.alert-exclui {
  --background: var(--ion-color-tijolo);
  --max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;



  .alert-message {
    font-size: 17px;
    color: white;
    max-height: 100%;
  }

  .alert-button-group {
   /* position: absolute;
    right: 0;
    width: 50px;*/

    button {
      color: white;
    }
  }
}

.swiper-pagination.swiper-pagination-bullets {
  bottom: 50px !important;
}

input.padrao {
  border-radius: 8px;
  display: inline-block;
  padding: 10px 8px;
  width: 100%;
  border: 0;
  outline: none;
  box-sizing: border-box;
  appearance: none;
  background: var(--ion-color-input);
}

::placeholder {
  color: #bebdbc;
}

@media screen and (device-aspect-ratio: 40/71) {
  .card-content-ios{
    padding: 5px;
  }
  ion-card{
    margin: 10px;
  }
  .col-6{
    padding: 0!important;
  }

}